<template>
  <div id="app">
    <ShrimpSystem />
  </div>
</template>

<script>
import ShrimpSystem from './components/ShrimpSystem.vue';

export default {
  name: 'App',
  components: {
    ShrimpSystem
  }
};
</script>

<style>
/* 您的全域樣式可以放在這裡 */
</style>
