<template>
  <v-app style="background-color: #F3F3F3">
    <v-navigation-drawer app temporary v-model="drawer" color="#1bacbc">
      <v-list-item-group>
        <v-list-item @click="navigateTo('home')">
          <v-list-item-content>
            <v-list-item-title style="color:white;font-weight:bold;">
              首頁
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="navigateTo('calculation')">
         <v-list-item-content>
            <v-list-item-title style="color:white;font-weight:bold;">
              飼料量試算
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-navigation-drawer>

    <v-app-bar app dense flat color="#008B8B" height="40vh">
      <div style="color:white;font-weight:bold;">白蝦試算系統</div>
      <v-spacer></v-spacer>
      <v-btn icon @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main style="height: 60vh;">
      <v-container v-if="selectedPage === 'home'" class="d-flex justify-center align-center fill-height flex-column">
        <h1 style="color: #008B8B; font-weight: bold;">歡迎白蝦試算系統</h1>
        <v-btn @click="selectedPage = 'calculation'" color="#008B8B" class="mt-4 text-center" style="color: white; font-size: 20px; padding: 30pt ,30pt ,30pt ,30pt ; width: 180pt;">前往飼料量試算</v-btn>
      </v-container>
      <v-container v-if="selectedPage === 'calculation'" class="d-flex justify-center align-center fill-height">
        <v-col cols="12" md="6">
          <v-card class="pa-5" outlined>
            <v-card-title class="font-weight-bold text-center d-flex justify-center align-center mb-5" style="color: #008B8B; font-size: 30px;" >白蝦飼料量計算</v-card-title>
            <v-card-text>
              <v-text-field v-model="shrimpCount" label="白蝦的數量" suffix="尾數"></v-text-field>
              <v-text-field v-model="shrimpWeight" label="白蝦的平均克數" suffix="克"></v-text-field>
              <v-text-field v-model="growthRate" label="成長率" suffix="%"></v-text-field>
              <v-row>
                <v-col cols="6"><v-btn @click="calculateFeed" class="mb-2" block>計算</v-btn></v-col>
                <v-col cols="6"><v-btn @click="resetFields" class="mb-2" block>重置</v-btn></v-col>
              </v-row>
              <v-divider></v-divider>
              <transition name="pop-up">
                <div v-if="totalFeedKg" class="result-animation">
                 <v-icon color="#008B8B">mdi-alert-circle</v-icon>
                    需要餵食的公斤數: <span style="color: #008B8B">{{ totalFeedKg }}</span>
                </div>
              </transition>
              <v-dialog v-model="errorDialog" max-width="300">
                <v-card>
                  <v-card-title class="headline" style="color: #008B8B; font-weight: bold;">
                    <v-icon class="mr-2" color="red">mdi-alert</v-icon>
                    錯誤
                  </v-card-title>
                  <v-card-text>沒有輸入數值</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="errorDialog = false">關閉</v-btn>
                    </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      errorDialog: false,
      drawer: false,
      selectedPage: 'home',
      shrimpCount: null,
      shrimpWeight: null,
      growthRate: null,
      totalFeedKg: null
    };
  },
  methods: {
    navigateTo(page) {
    this.selectedPage = page;
    this.drawer = false;
    },
    calculateFeed() {
      if (this.shrimpCount === null || this.shrimpWeight === null || this.growthRate === null) {
      this.errorDialog = true;
      return;
      }
      const survivalRate = 0.8;
      const dailyFeed = this.shrimpCount * this.shrimpWeight * (this.growthRate / 100) * survivalRate;
      this.totalFeedKg = (dailyFeed / 1000).toFixed(2);
    },
    resetFields() {
      this.shrimpCount = null;
      this.shrimpWeight = null;
      this.growthRate = null;
      this.totalFeedKg = null;
    }
  }
};
</script>

<style scoped>
[v-color=secondary] {
  border-bottom: none;
}
.pop-up-enter-active, .pop-up-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}
.pop-up-enter, .pop-up-leave-to {
  transform: translateY(1rem);
  opacity: 0;
}

.result-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 20px;
  border: 1pt solid #008B8B;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

</style>
